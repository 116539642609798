/* eslint-disable react/prop-types */
import * as React from "react";
import Layout from "../layouts/MasterLayout/MasterLayout";
import RawComponent from "../components/RawComponent/RawComponent";
import DocumentComponent from "../components/DocumentComponent/DocumentComponent";
import { MDBCol, MDBContainer, MDBRow, MDBBtn, MDBIcon } from "mdbreact";
import { FormatDate } from "../helpers/formatters";
import { Link } from "gatsby";
import SEO from "../components/SEO/SEO";

export default function EditalPage({ pageContext: { edital } }) {
	let blocos = [];

	// Recupera as meta tags do edital
	let metaTags = (edital?.Configuracoes?.AgruTag?.Value)?.split(",").map(
		(term) => {
			return term.trim();
		}
	);

	// Trata os blocos de conteúdo
	if (edital?.Blocks?.length > 0) {
		edital.Blocks.forEach((block) => {
			let componenteRetorno;

			switch (block.Type) {
				case "Piranha.Extend.Blocks.HtmlBlock":
					componenteRetorno = <RawComponent content={block.Body.Value} />;
					break;
				case "Piranha.Extend.Blocks.SeparatorBlock":
					componenteRetorno = <hr />;
					break;
				case "FeevaleData.Models.Blocks.DocumentGroupBlock": {
					let documents = [];

					block?.Items?.forEach((doc) => {
						if (doc?.Body?.Media !== null) {
							documents.push({
								Title: doc.Body.Media.Title,
								ContentType: doc.Body.Media.ContentType,
								PublicUrl: doc.Body.Media.PublicUrl,
							});
						}
					});
					componenteRetorno = <DocumentComponent items={documents} />;
					break;
				}
				case "FeevaleData.Models.Blocks.DocumentBlock":
					componenteRetorno = (
						<DocumentComponent
							items={[
								{
									Title: block.Body.Media.Title,
									ContentType: block.Body.Media.ContentType,
									PublicUrl: block.Body.Media.PublicUrl,
								},
							]}
						/>
					);
					break;
			}
			blocos.push(componenteRetorno);
		});
	}

	return (
		<Layout>
			<SEO
				title={edital?.Title}
				description={edital?.MetaDescription}
				meta={metaTags}
			/>
			<MDBContainer>
				<MDBRow>
					<MDBCol className="my-5">
						<h1 style={{color: "#6d6e72"}}>{edital?.Title}</h1>
						<p className="text-muted font-italic font-weight-light">
							Última modificação: {FormatDate(edital?.LastModified)}
						</p>
						<div style={{color:"#747373"}}>
							{blocos.map((i) => {
								return i;
							})}
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="d-flex justify-content-end">
						<Link to="/">
							<MDBBtn color="success" outline className="px-5">
								<MDBIcon
									icon="chevron-circle-left"
									aria-hidden="true"
								/>{" "}
								Ver todos os editais
							</MDBBtn>
						</Link>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
